import React, { FC, useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { getOrderDeliveries } from "src/api";
import { RadioItem, RadioGroup, EditAddress } from "src/components";
import { ApplicationStore } from "src/store";
import { Delivery, Shop, UserAddress } from "src/types";

import { ShopSelector, AddressSelector } from "../";
import { StyledMain } from "../styles";

import {
  TextArea,
  CommentWrapper,
  CommentLabel,
  AddressButton,
  PlusIcon,
} from "./styles";
import { ChooseDeliveryProps, DeliveryMeta } from "./types";

const ChooseDelivery: FC<ChooseDeliveryProps> = ({
  onSelect,
  onSelectDelivery,
  products,
  isRetail,
}) => {
  const [isAddAddressActive, setIsAddAddressActive] = useState(false);
  const [isEditAddressActive, setIsEditAdressActive] = useState(false);
  const [addedAddress, setAddedAddress] = useState({
    city: "",
    street: "",
    house: "",
    flat: "",
    zip_code: "",
  });
  const [allDeliveries, setAllDeliveries] = useState<Delivery[]>();
  const { userAddresses: addresses } = useSelector(
    (s: ApplicationStore) => s.user
  );
  const handleActive = (state: boolean) => {
    setIsEditAdressActive(state);
  };

  useEffect(() => {
    setAddress(
      addresses?.filter(
        (address) =>
          address.city === addedAddress.city &&
          address.street === addedAddress.street &&
          address.house === addedAddress.house &&
          address.flat === addedAddress.flat &&
          address.zip_code === addedAddress.zip_code
      )[0]
    );
  }, [
    addedAddress.city,
    addedAddress.flat,
    addedAddress.house,
    addedAddress.street,
    addedAddress.zip_code,
    addresses,
  ]);

  useEffect(() => {
    getOrderDeliveries().then(({ data }) => {
      setAllDeliveries(data.deliveries);
    });
  }, [products]);

  const [delivery, setDelivery] = useState<Delivery>();
  const currentDeliveryKey = delivery && String(delivery.id);

  const [shop, setShop] = useState<Shop>();
  const [comment, setComment] = useState<string>("");
  const [address, setAddress] = useState<UserAddress>();

  if (!address && addresses && addresses.length === 1) {
    setAddress(addresses[0]);
  }

  useEffect(() => {
    if (!address && addresses) {
      setAddress(addresses[0]);
    }
  }, [address]);

  useEffect(() => {
    const DELIVERY = localStorage.getItem("delivery");
    const DELIVERYMETA = localStorage.getItem("meta");
    if (DELIVERY !== "undefined" && DELIVERY && DELIVERYMETA) {
      const META: DeliveryMeta = JSON.parse(DELIVERYMETA);
      const DEL = JSON.parse(DELIVERY);
      setShop(META.shop);
      setAddress(META.address);

      if (META.comment) {
        setComment(META.comment);
      }

      setDelivery(DEL);
    }
  }, []);

  const deliveryItems = useMemo(
    () =>
      allDeliveries?.map<RadioItem>((delivery) => ({
        value: String(delivery.id),
        text: delivery.name,
      })),
    [allDeliveries]
  );

  const handleChangeDelivery = (key: string) => {
    const delivery = allDeliveries?.find(
      (delivery) => String(delivery.id) === key
    );
    setDelivery(delivery);
    delivery && onSelectDelivery(delivery.price);
  };

  const handleIsAdressSelectorChanged = () => {
    setIsAddAddressActive(false);
    setIsEditAdressActive(false);
  };

  const withShop = !!delivery?.shops;
  const withAddress = !withShop;

  const handleAddAddress = () => {
    setIsAddAddressActive((state: boolean) => !state);
  };

  const isReady = !!delivery && (withAddress ? !!address : true);

  const isAddressTheOnly = withAddress && !!addresses && addresses.length === 1;
  const isAddressMultiply = withAddress && !!addresses && addresses.length > 1;
  const isNotAddress = withAddress && !!addresses && addresses.length === 0;

  useEffect(() => {
    if (!delivery || !isReady) return;

    const meta: DeliveryMeta = {};
    if (withAddress) meta.address = address;
    if (withShop) meta.shop = shop;
    meta.comment = comment;

    onSelect(delivery, meta);
  }, [address, comment, delivery, isReady, shop, withAddress, withShop]);

  useEffect(() => {
    if (isRetail && allDeliveries) {
      const meta: DeliveryMeta = {};
      meta.shop = shop;
      meta.comment = comment;

      onSelect(allDeliveries[0], meta);
    }
  }, [comment, shop, isRetail, allDeliveries]);

  useEffect(() => {
    if (delivery?.shops && isRetail) {
      setShop(delivery.shops[0]);
    }
  }, [delivery]);

  return (
    <>
      {deliveryItems && (
        <>
          <StyledMain>
            {!isRetail && (
              <RadioGroup
                name="delivery"
                items={deliveryItems}
                current={currentDeliveryKey}
                onChange={handleChangeDelivery}
                className={"radioGroupInCheckout mb-15 back--blue pl-45"}
              />
            )}
            {delivery && (
              <div className="choose_address p-45 back--blue">
                {withShop && delivery.shops && (
                  <ShopSelector
                    shops={delivery.shops}
                    value={shop}
                    onChange={setShop}
                  />
                )}
                {!withShop && (
                  <>
                    {isAddressMultiply && (
                      <>
                        <AddressSelector
                          onChange={setAddress}
                          value={address}
                          isChanged={handleIsAdressSelectorChanged}
                        />
                        {!isAddAddressActive && (
                          <EditAddress
                            handleSave={(values) => {
                              setAddedAddress(values);
                            }}
                            handleActive={handleActive}
                            address={address}
                          />
                        )}
                      </>
                    )}
                    {isAddressTheOnly && !!addresses && !isAddAddressActive && (
                      <>
                        <EditAddress
                          address={addresses[0]}
                          handleActive={handleActive}
                        />
                      </>
                    )}
                    {isNotAddress && <EditAddress />}
                    {isAddAddressActive && (
                      <EditAddress
                        handleSave={(values) => {
                          handleAddAddress();
                          setAddedAddress(values);
                          document
                            .getElementById("addressdelivery")
                            ?.scrollIntoView({
                              behavior: "smooth",
                            });
                        }}
                        isAddAddress={isAddAddressActive}
                        handleActive={handleIsAdressSelectorChanged}
                      />
                    )}
                    {!isNotAddress &&
                      !isAddAddressActive &&
                      !isEditAddressActive && (
                        <AddressButton onClick={handleAddAddress}>
                          <PlusIcon />
                          Добавить адрес
                        </AddressButton>
                      )}
                  </>
                )}
                <CommentWrapper>
                  <CommentLabel>Комментарий к заказу</CommentLabel>
                  <TextArea
                    className="resize--off"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                </CommentWrapper>
              </div>
            )}

            {/* 
              TODO: временно скрываем блок 
            {currentDeliveryKey !== "1" && (
              <>
                <Text>Стоимость доставки: {delivery?.price || 0} ₽</Text>
                {!!delivery?.interval && (
                  <Text>
                    Срок {delivery.id === 2 ? "доставки" : "отгрузки"}:{" "}
                    {delivery.interval}
                  </Text>
                )}
              </>
            )} */}
          </StyledMain>
        </>
      )}
    </>
  );
};
export default ChooseDelivery;
