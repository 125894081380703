import React, { FC } from "react";
import { Switch, Route } from "react-router-dom";

import { ROUTES } from "src/constants";

import { CatalogListPage } from "./CatalogList";
import { ProductPage } from "./Product";

const CatalogPage: FC = () => {
  return (
    <Switch>
      <Route exact path={`${ROUTES.product}:id`} component={ProductPage} />
      <Route
        path={`${ROUTES.catalog}filter-b:brand_id`}
        component={ProductPage}
      />
      <Route render={() => <CatalogListPage />} />
    </Switch>
  );
};

export default CatalogPage;
