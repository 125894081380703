import React, {FC, useCallback, useContext, useEffect, useRef} from "react";
import { useDispatch } from "react-redux";

import { setCheckout, setCurrentWorkFlow } from "../../actions";
import {
  addToCheckout,
  addToCheckoutWithoutUuid,
  getCheckout,
  getProductQuantity,
} from "../../api";
import {CheckoutContext} from "../../context/CheckoutContext";
import { SectionTree } from "../../types";
import {
  getBasketId,
  sendTagManagerAddToCheckoutData,
  setBasketId,
} from "../../utils";

//import {getBasketId} from "../../utils";

export const OutfitPage: FC = () => {
  const smartRef = useRef(null as HTMLElement | null);

  const { setIsCartOpened } = useContext(CheckoutContext);

  const dispatch = useDispatch();

  const addClickEventToAddedElements = useCallback((addedNodes) => {
    addedNodes.forEach((node: Node) => {
      if (node instanceof HTMLElement) {
        const elements = node.querySelectorAll(
          ".garderobo-widget-popup-list-item-text-cart-btn"
        );
        elements.forEach((element) => {
          element.addEventListener("click", handleClick);
        });
      }
    });
  }, []);

  const handleClick = (event: Event) => {
    const target = event.target as HTMLElement;
    if (target) {

      const productId = target.getAttribute("data-product-id");
      const offerId = target.getAttribute("data-offer-id");

      const checkoutId = getBasketId();
      handleAddToCheckout(setIsCartOpened, productId, checkoutId, 1, offerId);
    }
  };
  const findProductByIdInData = (itemId: string, items: any) => {
    if (items) {
      return items.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => {
          return item.product_id === parseInt(itemId);
        }
      );
    }
    return null;
  };

  const handleAddToCheckout = useCallback(
    (
      toggleCheckout,
      filteredProductId,
      checkoutId,
      filteredQuantity,
      filteredOfferId
    ) =>
      getProductQuantity(filteredProductId, checkoutId).then(({ data }) => {
        if (data.success) {
          if (
            !!filteredProductId &&
            !!checkoutId &&
            !!filteredQuantity &&
            !!filteredOfferId
          ) {
            if (checkoutId === "undefined" || checkoutId === null) {
              addToCheckoutWithoutUuid({
                product_id: filteredProductId,
                quantity: filteredQuantity,
                offer_id: filteredOfferId,
              }).then(({ data }) => {
                setBasketId(data.basket_uuid);
                dispatch(
                  setCurrentWorkFlow({
                    userWorkFlow: "hasUuid",
                  })
                );
                getCheckout(data.basket_uuid).then(({ data }) => {
                  dispatch(setCheckout(data));
                  if (window.matchMedia("(min-width: 768px)").matches) {
                    toggleCheckout(true);
                  }
                  const product = findProductByIdInData(
                    filteredProductId,
                    data.basket.items
                  );
                  sendTagManagerAddToCheckoutData({
                    productId: product.product_id,
                    vendorCode: product.size_id_1c,
                    productName: product.offer.name,
                    productPrice: product.offer.new_price,
                    productBrand: product.product.brand.name,
                    category: product.section_tree
                      .map((category: SectionTree) => category.name)
                      .join("/"),
                  });
                });
              });
            } else {
              addToCheckout(checkoutId, {
                product_id: filteredProductId,
                basket_uuid: checkoutId,
                quantity: filteredQuantity,
                offer_id: filteredOfferId,
              }).then(() => {
                getCheckout(checkoutId).then(({ data }) => {
                  dispatch(setCheckout(data));
                  if (window.matchMedia("(min-width: 768px)").matches) {
                    toggleCheckout(true);
                  }
                  const product = findProductByIdInData(
                    filteredProductId,
                    data.basket.items
                  );
                  sendTagManagerAddToCheckoutData({
                    productId: product.product_id,
                    vendorCode: product.size_id_1c,
                    productName: product.offer.name,
                    productPrice: product.offer.new_price,
                    productBrand: product.product.brand.name,
                    category: product.section_tree
                      .map((category: SectionTree) => category.name)
                      .join("/"),
                  });
                });
              });
            }
          }
        }
      }),
    []
  );

  function getProductItemId(element: Element | null): string | null {
    if (element instanceof HTMLElement && element) {
      return element.getAttribute("data-product-item-id");
    }
    return null;
  }

  function getOfferId(element: Element | null): string | null {
    if (!(element instanceof HTMLElement && element)) {
      return null;
    }

    const offers = element.querySelector(".garderobo-widget-sizes");

    if (!(offers instanceof HTMLSelectElement)) {
      return null;
    }

    return offers.value;
  }

  useEffect(() => {
    if (smartRef.current) {
      const observer = new MutationObserver((mutationsList, observer) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
            addClickEventToAddedElements(mutation.addedNodes);
          }
        });
      });
      observer.observe(smartRef.current, { childList: true, subtree: true });
      return () => {
        observer.disconnect();
      };
    }
  }, [smartRef.current]);
  return (
    <>
      <div
        id="modatech-widgets"
        ref={smartRef as React.RefObject<HTMLDivElement>}
      ></div>
    </>
  );
};
