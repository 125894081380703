import { UserTypes } from "src/store";
import {
  SetUser,
  SetUserData,
  SetUserAddresses,
  SetUserCurrentWorkFlow,
} from "src/store/user";
import { AuthData, UserData, UserAddress, UserWorkFlow } from "src/types";

export const authorizeUser = (data: AuthData): SetUser => ({
  type: UserTypes.SET,
  payload: {
    isAuthorized: true,
    userWorkFlow: data.userWorkFlow,
    isFirstTime: data.first_time,
    info: { checkoutId: data.basket_uuid },
  },
});

export const setUserData = (data: UserData): SetUserData => ({
  type: UserTypes.SET_DATA,
  payload: {
    checkoutId: data.basket_uuid,
    bonuses: data.bonuses,
    userEmail: data.email,
    userId: data.user_id,
    userName: data.name,
    userFirstName: data.first_name,
    userLastName: data.last_name,
    phone: data.default_phone,
    genders: data.genders,
    genderId: data.gender_id,
    date_of_birth: data.date_of_birth,
    customerCards: data.customer_cards,
    savings: data.savings,
    min_discount_percent: data.min_discount_percent,
    is_sms: data.is_sms,
    is_email: data.is_email,
    is_mobile_push: data.is_mobile_push,
    orders_count: data.orders_count,
  },
});

export const setUserAddresses = (
  addresses: UserAddress[]
): SetUserAddresses => ({
  type: UserTypes.SET_ADDRESSES,
  payload: addresses,
});

export const setCurrentWorkFlow = (
  data: UserWorkFlow
): SetUserCurrentWorkFlow => ({
  type: UserTypes.SET_CURRENT_WORKFLOW,
  payload: { ...data },
});
