import React, { FC, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { CheckoutSelectItem, CheckoutSelect, Button } from "src/components";
import {
  PlusIcon,
  AddressButton,
} from "src/components/PersonalInfo/PersonalInfo.styles";
import { ROUTES } from "src/constants";
import { ApplicationStore } from "src/store";
import { stringifyAddress } from "src/utils";

import { CurrentAddress } from "../index";

import { AddressSelectorProps } from "./types";

const AddressSelector: FC<AddressSelectorProps> = ({
  value,
  onChange,
  isChanged,
}) => {
  const history = useHistory();
  const { userAddresses: addresses, isAuthorized } = useSelector(
    (s: ApplicationStore) => s.user
  );

  const items = useMemo(
    () =>
      addresses?.map<CheckoutSelectItem>((address) => ({
        value: address.id,
        text: stringifyAddress(address),
      })),
    [addresses]
  );

  useEffect(() => {
    onChange(addresses?.find((address) => address.id === value?.id));
    isChanged?.();
  }, [addresses, onChange, value]);

  if (addresses && !addresses.length) {
    return <></>;
  }

  return (
    <>
      <CurrentAddress label={"Адрес доставки: "} address="" />
      {items ? (
        <CheckoutSelect
          items={items}
          value={value?.id}
          label={!addresses?.length ? "Добавить адрес" : "Выбрать адрес"}
          onChange={(key) =>
            onChange(addresses?.find((address) => address.id === key))
          }
        />
      ) : (
        <>
          {isAuthorized ? (
            <AddressButton to={ROUTES.newAddress}>
              <PlusIcon />
              Добавить адрес
            </AddressButton>
          ) : (
            <Button onClick={() => void history.push(ROUTES.auth)}>
              Войти или зарегистрироваться
            </Button>
          )}
        </>
      )}
    </>
  );
};
export default AddressSelector;
