import React, { useCallback, useState, FC } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";

import { setCheckout } from "src/actions";
import {
  removeFromCheckout,
  getCheckout,
  changeCountInCheckout,
} from "src/api";
import { ApplicationStore } from "src/store";
import { convertImageUrl, formatCurrency, getBasketId } from "src/utils";

import {
  ProductNotAvailable,
  ProductNotAvailableIcon,
  Container,
  ContainerInner,
  InfoContainerInner,
  InfoContainer,
  Picture,
  Image,
  Name,
  Kind,
  Size,
  Price,
  DeleteButton,
  PriceContainer,
  OldPrice,
  ProductCount,
  CountButton,
  MinusIcon,
  PlusIcon,
  Count,
  CountTitle,
  Quantity,
} from "./CheckoutItem.styles";
import { CheckoutItemProps } from "./CheckoutItem.types";

const CheckoutItem: FC<CheckoutItemProps> = (props) => {
  const { item, isShowedCount, categories, total } = props;
  const {
    product,
    discount_price,
    id,
    size,
    gallery,
    price,
    quantity,
    available_quantity,
    in_stock,
    url,
  } = item;

  const imageSrc = gallery && gallery.length ? gallery[0] : "";
  const isCheckoutPage = window.location.href.includes("checkout");

  const dispatch = useDispatch();
  const { info } = useSelector((state: ApplicationStore) => state.user);
  const [isLoading, setLoading] = useState(false);
  const addItemTagManager = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "add-to-cart",
        vendorcode: `${product.id_1c}#${
          product.size_id_1c ? product.size_id_1c : "ONE_SIZE"
        }`,
        ecommerce: {
          currencyCode: "RUB",
          add: {
            products: [
              {
                name: product.name,
                id: product.id,
                price: Number(discount_price || price),
                brand: product.brand.name,
                category: categories,
                quantity: 1,
              },
            ],
          },
        },
      },
    });
  }, [categories, price, product.brand.name, product.id, product.name]);
  const removeItemTagManager = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "remove-from-cart",
        vendorcode: `${product.id_1c}#${
          product.size_id_1c ? product.size_id_1c : "ONE_SIZE"
        }`,
        ecommerce: {
          remove: {
            products: [
              {
                name: product.name,
                id: product.id,
                price: Number(discount_price || price),
                brand: product.brand.name,
                category: categories,
                quantity: 1,
              },
            ],
          },
        },
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });

    if (total && total <= 1) {
      TagManager.dataLayer({
        dataLayer: {
          event: "clear-cart",
        },
      });
    }
  }, [categories, price, product.brand.name, product.id, product.name, total]);

  const removeItem = useCallback(() => {
    const checkoutId = getBasketId() || info?.checkoutId;
    if (checkoutId) {
      setLoading(true);

      removeFromCheckout(checkoutId, id)
        .then(() => {
          getCheckout(checkoutId).then(({ data }) => {
            dispatch(setCheckout(data));

            TagManager.dataLayer({
              dataLayer: {
                removefromcart: product.id,
              },
            });
            removeItemTagManager();
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            getCheckout(checkoutId).then(({ data }) => {
              dispatch(setCheckout(data));
            });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [dispatch, id, info, product.id, removeItemTagManager]);

  const onChangeCount = useCallback(
    (side: string) => {
      const checkoutId = getBasketId() || info?.checkoutId;
      if (checkoutId) {
        let count: number = quantity;
        if (side === "plus") {
          count++;
        }

        if (side === "minus") {
          count--;
          count = count === 0 ? 1 : count;
        }
        setLoading(true);

        changeCountInCheckout(checkoutId, id, count)
          .then(() => {
            getCheckout(checkoutId).then(({ data }) => {
              dispatch(setCheckout(data));

              if (side === "plus") {
                addItemTagManager();
              }

              if (side === "minus") {
                removeItemTagManager();
              }
            });
          })
          .finally(() => setLoading(false));
      }
    },
    [addItemTagManager, dispatch, id, info, quantity, removeItemTagManager]
  );

  if (!product) {
    return null;
  }

  return (
    <Container className={props.className}>
      <ContainerInner>
        <InfoContainer>
          {!in_stock && (
            <ProductNotAvailable>
              <ProductNotAvailableIcon>!</ProductNotAvailableIcon>
              <p>Нет в наличии</p>
            </ProductNotAvailable>
          )}
          <InfoContainerInner disabled={!in_stock}>
            {!window.location.href.includes("checkout") ? (
              <Name>
                <a href={url}>{product.brand.name}</a>
              </Name>
            ) : (
              <Name>{product.brand.name}</Name>
            )}
            <Kind>{product.type.name}</Kind>
            <Size>
              {(!isCheckoutPage || size?.name || isShowedCount) && (
                <div>
                  {size?.name ? (
                    <>Размер: {size?.name}</>
                  ) : (
                    <CountTitle>
                      Количество: {!isShowedCount && "OneSize"}
                    </CountTitle>
                  )}
                </div>
              )}
              {isShowedCount && (
                <ProductCount>
                  <CountButton
                    onClick={() => onChangeCount("minus")}
                    aria-label="Уменьшить"
                    disabled={quantity === 1}
                  >
                    <MinusIcon />
                  </CountButton>
                  <Count>{quantity}</Count>
                  <CountButton
                    onClick={() => onChangeCount("plus")}
                    aria-label="Увеличить"
                    disabled={quantity === available_quantity}
                  >
                    <PlusIcon />
                  </CountButton>
                </ProductCount>
              )}
            </Size>
            <PriceContainer>
              {+price > +discount_price && (
                <OldPrice>{formatCurrency(Number(price || 0))}</OldPrice>
              )}

              <Price>{formatCurrency(Number(discount_price || 0))}</Price>
            </PriceContainer>
          </InfoContainerInner>
          {in_stock && (
            <DeleteButton onClick={removeItem} disabled={isLoading}>
              Удалить
            </DeleteButton>
          )}
        </InfoContainer>
        <Picture disabled={!in_stock}>
          {!window.location.href.includes("checkout") ? (
            <a href={url}>
              <Image
                src={convertImageUrl(imageSrc, 310, 400)}
                alt={product.name}
              />
            </a>
          ) : (
            <Image
              src={convertImageUrl(imageSrc, 310, 400)}
              alt={product.name}
            />
          )}
          {!isShowedCount && quantity > 1 && (
            <Quantity>{quantity} шт.</Quantity>
          )}
        </Picture>
      </ContainerInner>
      {!in_stock && (
        <DeleteButton onClick={removeItem} disabled={isLoading}>
          Удалить
        </DeleteButton>
      )}
    </Container>
  );
};

export default CheckoutItem;
