import React, { useCallback, FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { setUserData } from "src/actions";
import { setUserPersonalData } from "src/api";
import { EditPersonalInfo, Preloader } from "src/components";
import { PersonalDataChangeProps } from "src/components/EditPersonalInfo/EditPersonalInfo.types";
import { ROUTES } from "src/constants";
import { usePreloaderTimer } from "src/hooks/usePreloaderTimer";
import { ApplicationStore } from "src/store";
import { getBasketId } from "src/utils";

import {
  Container,
  Title,
  HeaderWrapper,
  BackLink,
  BackIcon,
  BackIconMobile,
} from "./PersonalDataEdit.styles";

const PersonalDataEdit: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const { userData } = useSelector((state: ApplicationStore) => state.user);
  const [isLoading, setLoading] = useState(false);

  const [isLoaderShown, onStartFetching, onEndFetching] = usePreloaderTimer();

  const onSubmit = useCallback(
    (values: PersonalDataChangeProps) => {
      onStartFetching();
      setLoading(true);

      setUserPersonalData(values)
        .then(({ data }) => {
          const newData = {
            ...values,
            basket_uuid: userData?.checkoutId || getBasketId() || "",
            user_id: userData?.userId || null,
            name: userData?.userName || "",
            default_phone: userData?.phone || "",
            genders: userData?.genders || null,
            customer_cards: userData?.customerCards || null,
            savings: userData?.savings || null,
            min_discount_percent: userData?.min_discount_percent || null,
            is_sms: userData?.is_sms || false,
            is_email: userData?.is_email || false,
            is_mobile_push: userData?.is_mobile_push || false,
            bonuses: userData?.bonuses || null,
          };
          dispatch(setUserData(newData));
          if (localStorage.getItem("CheckoutWithData")) {
            //localStorage.removeItem("inCheckout");
            history.push({
              pathname: ROUTES.checkout,
            });
          } else {
            history.push(ROUTES.personalData);
          }
        })
        .catch((error) => {
          setErrors({
            name:
              error.response.data.messages.first_name ||
              error.response.data.messages.last_name,
            phone: error.response.data.messages.default_phone,
            email: error.response.data.messages.email,
          });
        })
        .finally(() => {
          setLoading(false);
          onEndFetching();
        });
    },
    [dispatch, history, onEndFetching, onStartFetching, userData]
  );

  const fullName =
    userData?.userLastName || userData?.userFirstName
      ? `${userData.userFirstName || ""} ${userData.userLastName || ""}`
      : "";

  return (
    <>
      <HeaderWrapper>
        <BackLink to={ROUTES.personalData}>
          <BackIcon />
          <BackIconMobile />
          <span>Назад</span>
        </BackLink>
        <Title>Персональные данные</Title>
      </HeaderWrapper>
      <Container>
        {isLoaderShown && <Preloader />}
        {userData && (
          <EditPersonalInfo
            fio={fullName}
            phone={userData.phone || ""}
            email={userData.userEmail || ""}
            gender_id={userData.genderId}
            birthDate={userData.date_of_birth}
            onSubmit={onSubmit}
            errors={errors}
            isLoading={isLoading}
          />
        )}
      </Container>
    </>
  );
};

export default PersonalDataEdit;
