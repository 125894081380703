import React, { useCallback, useEffect, useState, useRef, FC } from "react";

import "./multiRangeSlider.css";
import { Input } from "./Input";

type propsMultiRangeType = {
  min: number;
  max: number;
  onChange: (
    event: any //  eslint-disable-line @typescript-eslint/no-explicit-any
  ) => void;
  minValQuery?: string | string[] | null;
  maxValQuery?: string | string[] | null;
};
const MultiRangeSlider: FC<propsMultiRangeType> = ({
  min,
  max,
  onChange,
  minValQuery,
  maxValQuery,
}) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const range = useRef({
    style: {
      left: "",
      width: "",
    },
  });

  async function setValueAsync(v: number, isMaxVal: boolean) {
    if (isMaxVal) {
      await setMaxVal(v);
    } else {
      await setMinVal(v);
    }
  }
  // Convert to percentage
  const getPercent = useCallback(
    (value, minim = min) => Math.round(((value - minim) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    if (min > minVal) setMinVal(min);
    if (max < maxVal) setMaxVal(max);
  }, [min, max, minVal, maxVal]);

  useEffect(() => {
    if (minValQuery) setMinVal(+minValQuery);
    if (maxValQuery) setMaxVal(+maxValQuery);
    if (minValQuery === null && maxValQuery === null) {
      setMinVal(min);
      setMaxVal(max);
    }
  }, [minValQuery, maxValQuery, min, max]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent, maxVal]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent, minVal]);
  return (
    <>
      <div className="digi-facet-slider">
        <div className="digi-facet-slider__slot">
          <span className="digi-facet-slider__label">от</span>
          <Input
            val={minVal}
            range={{
              min,
              max,
            }}
            handleBlur={(event, val) => {
              const value = Math.min(val, maxVal - 1);
              event.target.value = value;
              setValueAsync(value, false);
              setMinVal(val);
              onChange(event);
            }}
            name="filter[min_price]"
          />
          <span className="digi-facet-slider__label digi-facet-slider__label-rub">
            ₽
          </span>
        </div>
        <div className="digi-facet-slider__divider"></div>
        <div className="digi-facet-slider__slot">
          <span className="digi-facet-slider__label">до</span>
          <Input
            val={maxVal}
            range={{
              min,
              max,
            }}
            handleBlur={(event, val) => {
              const value = Math.max(val, minVal + 1);
              event.target.value = value;
              setValueAsync(value, true);
              onChange(event);
            }}
            name="filter[max_price]"
          />
          <span className="digi-facet-slider__label digi-facet-slider__label-rub">
            ₽
          </span>
        </div>
      </div>
      {min !== max && (
        <div className="container">
          <input
            type="range"
            name="filter[min_price]"
            min={min}
            max={max}
            value={minVal}
            onInput={(
              event: any //  eslint-disable-line @typescript-eslint/no-explicit-any
            ) => {
              const value = Math.min(Number(event.target.value), maxVal - 1);
              setMinVal(value);
            }}
            onMouseUp={(event) => {
              onChange(event);
            }}
            onTouchEnd={(event) => {
              onChange(event);
            }}
            className="thumb thumb--left"
            readOnly={true}
          />
          <input
            type="range"
            name="filter[max_price]"
            min={min}
            max={max}
            value={maxVal}
            onInput={(
              event: any //  eslint-disable-line @typescript-eslint/no-explicit-any
            ) => {
              const value = Math.max(Number(event.target.value), minVal + 1);
              setMaxVal(value);
            }}
            onMouseUp={(event) => {
              onChange(event);
            }}
            onTouchEnd={(event) => {
              onChange(event);
            }}
            className="thumb thumb--right"
            readOnly={true}
          />

          <div className="slider">
            <div className="slider__track">
              <div
                className="slider__track_chhosen"
                style={{
                  width: `${getPercent(maxVal, minVal)}%`,
                  left: `${getPercent(minVal)}%`,
                  maxWidth: `${getPercent(max, minVal)}%`,
                }}
              />
            </div>
            <div className="slider__range" />
          </div>
        </div>
      )}
    </>
  );
};

export default MultiRangeSlider;
